var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('core-view-template',{attrs:{"title":"OS & D Home"}},[_c('v-row',[_c('v-col',[_c('v-checkbox',{attrs:{"label":"Show Closed Headers"},on:{"change":_setup.getData},model:{value:(_setup.showClosedheaders),callback:function ($$v) {_setup.showClosedheaders=$$v},expression:"showClosedheaders"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1 scrollable",attrs:{"headers":_setup.tableHeaders,"items":_setup.headers,"items-per-page":-1,"fixed-header":true,"loading":_setup.gettingHeaders,"footer-props":{
          disableItemsPerPage: true,
          itemsPerPageText: '',
        }},scopedSlots:_vm._u([{key:"item.Header.PoArrivalDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.formatters.formatDate(item.Header.PoArrivalDate))+" ")]}},{key:"item.Header.UsernameCreatedBy",fn:function({ item }){return [_vm._v(" "+_vm._s(item.Header.CreatedByName)+" | "+_vm._s(item.Header.UsernameCreatedBy)+" ")]}},{key:"item.Header.CreatedDate",fn:function({ item }){return [_vm._v(" "+_vm._s(_setup.formatters.formatDatetime(item.Header.CreatedDate, true))+" ")]}},{key:"item.details",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
              name: 'osd_entry_detail',
              params: { entryHeaderId: item.Header.EntryHeaderId.toString() },
            }}},[_vm._v(" Details ")])]}},{key:"body.append",fn:function({}){return [_c('tr',[_c('td',{staticClass:"ma-0 pa-0",staticStyle:{"height":"10px"},attrs:{"colspan":_setup.headers.length + 10}},[(_setup.headers && _setup.headers.length > 0)?_c('infinite-loading',{attrs:{"identifier":_setup.infiniteId},on:{"infinite":_setup.infiniteHandler}}):_vm._e()],1)])]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
// Turn off @typescript-eslint/no-explicit-any for entire file
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue from 'vue';
import vueDebounce from 'vue-debounce';
import vueHeadful from 'vue-headful';
import VCurrencyField from 'v-currency-field';
import { ClientAuthenticatedAdalApiClient } from '@psp/pogona_adal_js';
import FetchApiClient from '@psp/pogona_fetch_authed_client';
import InfiniteLoading from 'vue-infinite-loading';
import store from '@/store';
import InfiniteLoader from './components/data/InfiniteLoader.vue';
import InfinitePaganation from './components/data/InfinitePaganation.vue';
import StoreNumberParser from './data/StoreNumberParser';

import './plugins';
import PogonaVueAppInsights from './plugins/appInsights';

import App from './App.vue';
import router from './router';

import './components';
import { initAppConfig } from './utils/AppConfig';

import 'vuetify/dist/vuetify.min.css';
import vuetify from './plugins/vuetify';
import '@/filters/dateFormat';
import '@/filters/dateTimeFormat';
import '@/filters/numberFormat';
import '@/filters/stringFormat';
import '@/filters/phoneFormat';
import '@/filters/zipFormat';
import '@/filters/boolFormat';
import '@/filters/newLineToBrFormat';
import { createPinia, PiniaVuePlugin } from 'pinia';
import { useVpFinanceStore } from './stores/vpfinancestore';
import { useVpNewItemStore } from './stores/vpnewitemstore';
import { useMerchStore } from './stores/merchstore';
import { useCouponimageuploadstore } from './stores/couponimageuploadstore';
import { useUnitOverrideStore } from './stores/unitoverridestore';
import { useEcomStore } from './stores/ecomstore';
import { useKeyTrackingStore } from './stores/keytrackingstore';
import { useOsdStore } from './stores/osdstore';

Vue.component('vue-headful', vueHeadful);

Vue.config.productionTip = false;

Vue.use(vueDebounce, {
  listenTo: ['input', 'keyup'],
});
Vue.prototype.$window = window;
Vue.prototype.$filters = Vue.options.filters;
// First get the config from /appconfig.json path
Vue.use({
  install(vue) {
    initAppConfig.then((config: any) => {
      if (config.appInsightsKey) {
        vue.use(PogonaVueAppInsights, {
          id: config.appInsightsKey,
          router,
          baseName: 'Pogona SPA',
        });
      }

      // once the config has been downloaded configure the adal client
      const apiBasePath =
        config.apiBasePath[0] === '/'
          ? `${document.location.host}${config.apiBasePath}`
          : config.apiBasePath;

      vue.prototype.$apiBasePath = apiBasePath;
      vue.prototype.$apiHostAndProtocol = `${document.location.protocol}//${
        apiBasePath.split('/')[0]
      }`;
      vue.prototype.$maxConcurrentRequests =
        config.maxConcurrentRequests === null ||
        config.maxConcurrentRequests === undefined ||
        config.maxConcurrentRequests === '0'
          ? 4
          : parseInt(config.maxConcurrentRequests, 10);

      vue.prototype.$renderStartWaitTimeMs =
        config.renderStartWaitTimeMs === null ||
        config.renderStartWaitTimeMs === undefined ||
        config.renderStartWaitTimeMs === '0'
          ? 300000
          : parseInt(config.renderStartWaitTimeMs, 10);

      vue.prototype.$checkBlobExistsTimeMs =
        config.checkBlobExistsTimeMs === null ||
        config.checkBlobExistsTimeMs === undefined ||
        config.checkBlobExistsTimeMs === '0'
          ? 2000
          : parseInt(config.checkBlobExistsTimeMs, 10);

      vue.prototype.$storageHost =
        config.storageHost[config.storageHost.length - 1] === '/'
          ? config.storageHost.substring(0, config.storageHost.length - 1)
          : config.storageHost;

      vue.prototype.$defaultStore = config.defaultStore || null;
      vue.prototype.$useCustomPrintDialog = config.useCustomPrintDialog || null;
      vue.prototype.$jsprintmanagerLicenseUrlV3 = config.jsprintmanagerLicenseUrlV3 || null;
      vue.prototype.$jsprintmanagerLicenseUrlV4 = config.jsprintmanagerLicenseUrlV4 || null;
      vue.prototype.$jsprintmanagerLicenseUrlV5 = config.jsprintmanagerLicenseUrlV5 || null;
      vue.prototype.$jsprintmanagerLicenseUrlV6 = config.jsprintmanagerLicenseUrlV6 || null;
      vue.prototype.$serverVersion = config.serverVersion || null;
      vue.prototype.$showServerUpdateMessage =
        config.showServerUpdateMessage === undefined || config.showServerUpdateMessage === null
          ? true
          : config.showServerUpdateMessage;
      vue.prototype.$authKeepAliveIntervalSeconds =
        (config.authKeepAliveIntervalSeconds && isNaN(config.authKeepAliveIntervalSeconds)) ===
        false
          ? Number(config.authKeepAliveIntervalSeconds)
          : 600;
      vue.prototype.$showIssueSubmitLink =
        config.showIssueSubmitLink === undefined || config.showIssueSubmitLink === null
          ? false
          : config.showIssueSubmitLink;
      vue.prototype.$publicPath = config.publicPath;
      vue.prototype.$defaultStorePrinterName = config.defaultStorePrinterName;
      vue.prototype.$defaultStorePrinterTrayName = config.defaultStorePrinterTrayName;

      const opts = {
        tenant: config.azureAdConfig.tenantId,
        clientId: config.azureAdConfig.clientId,
        scopes: config.azureAdConfig.scopes,
        authority: config.azureAdConfig.authority,
        baseUrl: `${window.location.protocol}//${apiBasePath}`,
        redirectUri: `${window.location.protocol}//${window.location.host}${config.publicPath}`,
        postLogoutRedirectUri: `${window.location.protocol}//${window.location.host}${
          config.publicPath
        }${config.publicPath.length === 0 ? '/' : ''}home`,
        onTokenSuccess: async (http, adalContext) => {
          const accounts = await adalContext.getAllAccounts();
          if (!accounts || accounts.length === 0) {
            document.location.reload();
          }

          const username = accounts[0].username;

          store.commit('initializeStore');
          store.commit('setStoreInitialized', true);

          if (username) {
            if (store.state.visibilitiesInitialized === false) {
              let visibilities = [];
              try {
                visibilities = (await http.get(`pogona/globalmenuvisibility/user/${username}`))
                  .data;
              } catch (e) {
                vue.prototype.$appInsights.trackException({
                  exception: e,
                  properties: {
                    text: `error getting globalmenuvisibility for user ${username}`,
                    username: username,
                    storeNumber: null,
                    route: vue.prototype.$appInsights.$route,
                    id: 'f54a8746-c733-4edd-9431-315882e9cefa',
                  },
                });
              }
              store.dispatch('app/setMenuVisibilities', visibilities);
              store.commit('setVisibilitiesInitialized', true);
            }

            store.dispatch('app/setUsername', username);

            const parsedStoreNumber = await StoreNumberParser.parse(username);

            if (
              store.state.app.storeNumber === null ||
              store.state.app.storeNumber === undefined ||
              store.state.app.storeNumber.length === 0 ||
              (parsedStoreNumber && parsedStoreNumber !== store.state.app.storeNumber)
            ) {
              store.dispatch('app/setStoreNumber', parsedStoreNumber || config.defaultStore);
            }
          }

          store.dispatch('app/setShowModalLoading', false);
        },
      };

      // Configures an axios http client with an
      // interceptor to auto-acquire tokens
      const adalClient = new ClientAuthenticatedAdalApiClient(opts);
      adalClient.client.init().then(() => {
        // eslint-disable-next-line
        vue.prototype.$adalClient = adalClient;
        vue.prototype.$authApi = adalClient.client;
        store.commit('ecom/setClient', {
          http: adalClient.client.http,
          baseUrl: config.ecomApiBaseOverride,
        });
        store.commit('partnerfunding/setClient', {
          http: adalClient.client.http,
          baseUrl: config.ecomApiBaseOverride,
        });
        store.commit('ecomproduct/setClient', {
          http: adalClient.client.http,
          baseUrl: config.ecomApiBaseOverride,
        });
        store.commit('pcl/setClient', {
          http: adalClient.client.http,
          baseUrl: config.pclApiBaseOverride,
        });
        const vpFinanceStore = useVpFinanceStore();
        const vpNewItemStore = useVpNewItemStore();
        if (config.vendorApiBaseOverride && config.vendorApiBaseOverride.length > 0) {
          const vendorAdalClient = new ClientAuthenticatedAdalApiClient({
            ...opts,
            baseUrl: config.vendorApiBaseOverride + 'api',
          });
          vpFinanceStore.init({
            AuthClient: vendorAdalClient,
            Origin: window.location.origin,
            OverrideUrl: config.vendorApiBaseOverride,
          });
          vpNewItemStore.init({
            AuthClient: vendorAdalClient,
            Origin: window.location.origin,
            OverrideUrl: config.vendorApiBaseOverride,
          });
          vendorAdalClient.client.init().then(() => {
            store.commit('vendorportal/init', {
              AuthClient: vendorAdalClient,
              Origin: window.location.origin,
              OverrideUrl: config.vendorApiBaseOverride,
            });
          });
        } else {
          vpFinanceStore.init({
            AuthClient: adalClient,
            Origin: window.location.origin,
            OverrideUrl: config.vendorApiBaseOverride,
          });
          vpNewItemStore.init({
            AuthClient: adalClient,
            Origin: window.location.origin,
            OverrideUrl: config.vendorApiBaseOverride,
          });
          store.commit('vendorportal/init', {
            AuthClient: adalClient,
            Origin: window.location.origin,
            OverrideUrl: config.vendorApiBaseOverride,
          });
        }
        const merchStore = useMerchStore();
        merchStore.init(adalClient.client.http, config.ecomApiBaseOverride);
        const couponimageuploadstore = useCouponimageuploadstore();
        couponimageuploadstore.init(adalClient.client.http, config.ecomApiBaseOverride);
        const unitOverrideStore = useUnitOverrideStore();
        unitOverrideStore.init(adalClient.client.http, config.ecomApiBaseOverride);
        const ecomStore = useEcomStore();
        ecomStore.init(adalClient.client.http, config.ecomApiBaseOverride);
        const keyTrackingStore = useKeyTrackingStore();
        keyTrackingStore.init(adalClient.client.http, null);

        const initedFetchClient = new FetchApiClient(
          opts.clientId,
          opts.authority,
          opts.scopes,
          opts.baseUrl,
          opts.redirectUri,
          opts.postLogoutRedirectUri,
          opts.postLogoutRedirectUri,
        );
        initedFetchClient.init().then(() => {
          const osdStore = useOsdStore();
          osdStore.initClient(initedFetchClient);
        });
      });
    });
  },
});

Vue.config.errorHandler = function (err) {
  Vue.prototype.$error(err);
};
Vue.prototype.$store = store;

Vue.use(InfiniteLoading, {
  props: {
    spinner: 'none',
  },
  slots: {
    noMore: '',
    noResults: '',
    error: '',
  },
});

Vue.use(VCurrencyField, {
  locale: 'en',
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

Vue.component('infinite-loader', InfiniteLoader);
Vue.component('infinite-paganation', InfinitePaganation);

Vue.use(PiniaVuePlugin);

const app = new Vue({
  render: h => h(App),
  router,
  store,
  vuetify,
  pinia: createPinia(),
}).$mount('#app');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).app = app;

import { defineStore } from 'pinia';
import { ref } from 'vue';
import FetchApiClient from '@psp/pogona_fetch_authed_client';
import store from '@/store';
import {
  OsdEntryHeader,
  OsdEntryLine,
  OsdEntryLineImage,
  OsdEntryReason,
  OsdEntryStatus,
} from '@psp/osddto';
import FileSaver from 'file-saver';
import moment from 'moment';

export const useOsdStore = defineStore('osdStore', () => {
  const client = ref<FetchApiClient>();
  const headers = ref<EntryHeaderInfo[]>();
  const gettingHeaders = ref(false);
  const skipheaders = ref(0);
  const takeheaders = ref(10);
  const showClosedheaders = ref(false);

  const gettingHeader = ref(false);
  const gettingLines = ref(false);
  const gettingImages = ref(false);
  const downloadingImages = ref(false);
  const savingLines = ref(false);
  const selectedHeader = ref<OsdEntryHeader>();
  const lines = ref<OsdEntryLine[]>();
  const linesImages = ref<Record<number, OsdEntryLineImage[]>>();
  const entryReasons = ref<OsdEntryReason[]>();
  const entryStatuses = ref<OsdEntryStatus[]>();
  const lastHeaderInfoCount = ref(0);

  async function initClient(initedClient: FetchApiClient): Promise<any> {
    client.value = initedClient;
  }

  async function getEntryHeaders() {
    gettingHeaders.value = true;
    try {
      const headersResponse = await client.value!.get<EntryHeaderInfo[]>(
        `osd/entryheader/info?skip=${skipheaders.value}&take=${takeheaders.value}&showClosed=${showClosedheaders.value}`,
      );

      lastHeaderInfoCount.value = headersResponse.length;

      if (headers.value?.length) {
        headers.value?.push(...headersResponse);
      } else {
        headers.value = headersResponse;
      }
      gettingHeaders.value = false;
      skipheaders.value += takeheaders.value;
    } catch (err) {
      gettingHeaders.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD headers.',
          id: 'b2a680e7e-3c5f-47b3-a03a-3dbcc6947976',
        },
        { root: true },
      );
    }
  }

  async function getSelectedEntryHeader(entryHeaderId: number) {
    try {
      gettingHeader.value = true;

      selectedHeader.value = await client.value!.get<OsdEntryHeader>(
        `osd/entryheader/${entryHeaderId}`,
      );

      gettingHeader.value = false;
    } catch (err) {
      gettingHeader.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD header.',
          id: '16094609-230d-4d39-8f45-62a1cada077f',
        },
        { root: true },
      );
    }
  }

  async function getEntryLines(entryHeaderId: number) {
    try {
      gettingLines.value = true;

      lines.value = await client.value!.get<OsdEntryLine[]>(
        `osd/entryline?$filter=EntryHeaderId eq ${entryHeaderId}&$orderby=EntryLineId&$take=9999`,
      );
      gettingLines.value = false;
    } catch (err) {
      gettingLines.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD lines.',
          id: '4842535e-5e32-4aaf-b22e-ec1ff90b00a9',
        },
        { root: true },
      );
    }
  }

  async function getEntryLineImages(entryHeaderId: number) {
    try {
      gettingImages.value = true;
      linesImages.value = await client.value!.get<Record<number, OsdEntryLineImage[]>>(
        `osd/entrylineimage/header/${entryHeaderId}`,
      );
      gettingImages.value = false;
    } catch (err) {
      gettingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting line images.',
          id: 'a31dc019-9150-4533-a8d3-5de2a60529ca',
        },
        { root: true },
      );
    }
  }

  async function getEntryReasons() {
    try {
      entryReasons.value = await client.value!.get<OsdEntryReason[]>('osd/entryreason');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD reasons.',
          id: '8ec4a472-2f53-43f2-a3db-93017fcd32af',
        },
        { root: true },
      );
    }
  }

  async function getEntryStatuses() {
    try {
      entryStatuses.value = await client.value!.get<OsdEntryStatus[]>('osd/entrystatus');
    } catch (err) {
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting OSD statuses.',
          id: '59503ad5-11be-4725-92da-17598de8cf8d',
        },
        { root: true },
      );
    }
  }

  async function downloadImages(entryHeaderInfo: OsdEntryHeader, entryLineInfo: OsdEntryLine) {
    try {
      downloadingImages.value = true;
      const request = new Request(
        client.value?.combineWithBasePath(
          `osd/storage/zip/${entryLineInfo.EntryHeaderId}/${entryLineInfo.EntryLineId}`,
        ) ?? '',
      );
      var response = await client.value?.sendRequest(request);

      const nonAlphaRegex = /[^A-Za-z0-9]+/g;
      const multipleUnderscoreRegex = /_{2,}/g;
      // Build the filename
      let fileName = `${entryHeaderInfo.Ponumber}_${entryLineInfo.VendorName}_${entryLineInfo.Upc}_${moment().format('YYYY_MM_DD_HH_mm_ss')}`;
      fileName = fileName.replace(nonAlphaRegex, '_').replace(multipleUnderscoreRegex, '_');

      FileSaver.saveAs(await response?.blob(), `${fileName}.zip`);

      downloadingImages.value = false;
    } catch (err) {
      downloadingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting images.',
          id: 'e0961de1-4d76-4577-907d-a85e086e28ab',
        },
        { root: true },
      );
    }
  }

  async function downloadImage(blobLocation: string) {
    try {
      downloadingImages.value = true;
      const request = new Request(
        client.value?.combineWithBasePath(`osd/storage/${blobLocation}`) ?? '',
      );
      var response = await client.value?.sendRequest(request);

      const blobLocationParts = blobLocation.split('/');
      FileSaver.saveAs(await response?.blob(), blobLocationParts[blobLocationParts.length - 1]);

      downloadingImages.value = false;
    } catch (err) {
      downloadingImages.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error getting image.',
          id: '3e26f73e-3498-44ae-acc3-0e118475a529',
        },
        { root: true },
      );
    }
  }

  async function saveLines(lines: OsdEntryLine[], signatures: EntryLineSignature[]) {
    try {
      savingLines.value = true;

      for (const line of lines) {
        // closed?
        if (line.EntryStatusId === 2) {
          const signature = signatures.filter(x => x.EntryLineId === line.EntryLineId);
          if (signature && signature.length === 1 && signature[0].SignatureFile) {
            var data = new FormData();
            data.append('file', signature[0].SignatureFile);

            const request = new Request(
              client.value?.combineWithBasePath(
                `osd/storage/upload-line-image/${line.EntryHeaderId}/${line.EntryLineId}/true`,
              ) ?? '',
              {
                method: 'POST',
                body: data,
              },
            );
            var response = await client.value?.sendRequest(request);
            const signatureBlobLocation = await response?.text();
            line.SignatureBlobLocation = signatureBlobLocation || null;
          }
        } else {
          line.SignatureBlobLocation = null;
        }

        await client.value?.put<OsdEntryLine>(`osd/entryline/${line.EntryLineId}`, line);
      }

      if (lines && lines.length > 0) {
        await client.value!.post(`osd/entryheader/set-open-status/${lines[0].EntryHeaderId}`);
      }

      savingLines.value = false;
    } catch (err) {
      console.error(err);
      savingLines.value = false;
      store.commit(
        'setError',
        {
          err: err,
          text: 'Error saving lines.',
          id: 'd7becb80-cd88-44f1-a6c3-cd502fb8fcd5',
        },
        { root: true },
      );
    }
  }

  return {
    client,
    headers,
    gettingHeaders,
    gettingImages,
    gettingHeader,
    gettingLines,
    showClosedheaders,
    skipheaders,
    selectedHeader,
    lines,
    entryReasons,
    entryStatuses,
    linesImages,
    downloadingImages,
    savingLines,
    lastHeaderInfoCount,
    initClient,
    getEntryHeaders,
    getSelectedEntryHeader,
    getEntryLines,
    getEntryReasons,
    getEntryStatuses,
    getEntryLineImages,
    downloadImages,
    downloadImage,
    saveLines,
  };
});

export class EntryLineSignature {
  constructor(
    public EntryLineId: number,
    public SignatureFile: File | null,
    public HasSignature: Boolean,
  ) {}
}

export class EntryHeaderInfo {
  constructor(
    public Header: OsdEntryHeader,
    public FirstLine: number,
    public LineCount: number,
  ) {}
}

<template>
  <core-view-template title="OS & D Home">
    <v-row>
      <v-col>
        <v-checkbox v-model="showClosedheaders" label="Show Closed Headers" @change="getData" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :headers="tableHeaders"
          :items="headers"
          :items-per-page="-1"
          :fixed-header="true"
          :loading="gettingHeaders"
          :footer-props="{
            disableItemsPerPage: true,
            itemsPerPageText: '',
          }"
          class="elevation-1 scrollable"
        >
          <template v-slot:item.Header.PoArrivalDate="{ item }">
            {{ formatters.formatDate(item.Header.PoArrivalDate) }}
          </template>
          <template v-slot:item.Header.UsernameCreatedBy="{ item }">
            {{ item.Header.CreatedByName }} | {{ item.Header.UsernameCreatedBy }}
          </template>
          <template v-slot:item.Header.CreatedDate="{ item }">
            {{ formatters.formatDatetime(item.Header.CreatedDate, true) }}
          </template>
          <template v-slot:item.details="{ item }">
            <router-link
              :to="{
                name: 'osd_entry_detail',
                params: { entryHeaderId: item.Header.EntryHeaderId.toString() },
              }"
            >
              Details
            </router-link>
          </template>

          <template v-slot:body.append="{}">
            <tr>
              <td :colspan="headers.length + 10" class="ma-0 pa-0" style="height: 10px">
                <infinite-loading
                  v-if="headers && headers.length > 0"
                  @infinite="infiniteHandler"
                  :identifier="infiniteId"
                >
                </infinite-loading>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </core-view-template>
</template>

<script setup lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useOsdStore } from '@/stores/osdstore';
import { storeToRefs } from 'pinia';
import { useFormatters } from '@/composables/formatters';

defineComponent({
  name: 'OsdHome',
});

const store = useOsdStore();

const { headers, gettingHeaders, skipheaders, showClosedheaders, lastHeaderInfoCount } =
  storeToRefs(store);
const formatters = useFormatters();

const tableHeaders = [
  { text: 'ID', value: 'Header.EntryHeaderId' },
  { text: 'PO #', value: 'Header.Ponumber' },
  { text: 'Description', value: 'Header.Description' },
  { text: 'PO Arrival', value: 'Header.PoArrivalDate' },
  { text: 'Created By', value: 'Header.UsernameCreatedBy' },
  { text: 'Created On', value: 'Header.CreatedDate' },
  { text: '# of Lines', value: 'LineCount' },
  { text: 'Details', value: 'details', sortable: false },
];
const infiniteId = +new Date();

onMounted(async () => {
  await getData();
});

async function getData() {
  skipheaders.value = 0;
  headers.value = [];
  await store.getEntryHeaders();
}

function infiniteHandler($state: any) {
  store
    .getEntryHeaders()
    .then(() => {
      if (lastHeaderInfoCount.value > 0) {
        $state.loaded();
        skipheaders.value += 10;
      } else {
        $state.complete();
      }
    })
    .catch(() => {
      $state.complete();
    });
}
</script>

import { render, staticRenderFns } from "./OsdEntryDetail.vue?vue&type=template&id=349175b4&scoped=true"
import script from "./OsdEntryDetail.vue?vue&type=script&setup=true&lang=ts"
export * from "./OsdEntryDetail.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./OsdEntryDetail.vue?vue&type=style&index=0&id=349175b4&prod&scoped=true&lang=css"
import style1 from "./OsdEntryDetail.vue?vue&type=style&index=1&id=349175b4&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "349175b4",
  null
  
)

export default component.exports